<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('src\\views\\feedbacks\\detail.3447') }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.7564') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.createdAt | moment('DD.MM.YYYY HH:mm') }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.753') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.name }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.8334') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.phone }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.email') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.email }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.3691') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.comment }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\feedbacks\\detail.4585') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.link }}</v-col>
        </v-row>

        <v-row
            v-if="user.adminAccess && user.adminAccess.feedbacks >= ACCESS.FULL"
            align="center"
            justify="space-between"
            class="mx-0">
            <span/>
            <v-btn
                v-if="entity._id"
                color="error"
                @click="remove">{{ $t('src\\views\\feedbacks\\detail.6068') }}</v-btn>
        </v-row>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '../../helpers';
    import { ACCESS } from '@/vars';

    export default {
        name: 'FeedbackDetail',
        components: {
        },
        data: () => ({
            ACCESS,
            imageSrc,
            valid: true
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('feedbacks', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('feedbacks/get', { id: this.$route.params.id });
            } else {
                await this.$router.push({ name: 'feedback-list' });
            }
        },
        methods: {
            async remove() {
                await store.dispatch('feedbacks/delete', { id: this.entity._id });
                await this.$router.push({ name: 'feedback-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
